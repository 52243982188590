var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "touch-swipe",
          rawName: "v-touch-swipe.mouse.left",
          value: _vm.handleSwipe,
          expression: "handleSwipe",
          modifiers: { mouse: true, left: true },
        },
      ],
      staticClass: "app-sidebar sidebar-shadow",
      class: _vm.sidebarbg,
    },
    [
      _c(
        "div",
        { staticClass: "app-sidebar-content" },
        [
          _c(
            "VuePerfectScrollbar",
            { staticClass: "app-sidebar-scroll left-sidebar-menu" },
            [
              _c("div", { staticClass: "sidebar-top-bar" }, [
                _c("div", { staticClass: "sidebar-top-bar-btngroup" }, [
                  _vm._v(" 메뉴 바로가기 "),
                ]),
                _c(
                  "div",
                  { staticClass: "sidebar-top-bar-close" },
                  [
                    _c("q-btn", {
                      attrs: { flat: "", color: "gray", icon: "close" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleMobile("closed-sidebar-open")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "sidebar-my-menu" }, [
                _c("div", { staticClass: "sidebar-menu-all-title" }, [
                  _vm._v("나만의 메뉴"),
                  _vm.bookmarks.length > 0
                    ? _c(
                        "i",
                        {
                          staticClass: "material-icons sidebar-my-menu-edit",
                          on: { click: _vm.menuSetting },
                        },
                        [_vm._v("settings")]
                      )
                    : _vm._e(),
                ]),
                _vm.bookmarks.length == 0
                  ? _c("div", { staticClass: "no-bookmark" }, [
                      _vm._v("전체메뉴에서 나만의 메뉴를 지정하세요."),
                    ])
                  : _c(
                      "ul",
                      { staticClass: "sidebar-my-ol" },
                      _vm._l(_vm.bookmarks, function (item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "sidebar-my-menu-item" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "shortcut_a",
                                on: {
                                  click: function ($event) {
                                    return _vm.goMobilePage(item.href)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "sa_mw" }, [
                                  _c(
                                    "i",
                                    {
                                      class: [
                                        "material-icons sidebar-my-menu-icon",
                                        _vm.getIcons(item.icon, "color"),
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getIcons(item.icon, "icon"))
                                      ),
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "sidebar-my-menu-title",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        item.title.replace(" ", "<br/>")
                                      ),
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
              ]),
              _c(
                "div",
                { staticClass: "sidebar-my-menu-all" },
                [
                  _c("div", { staticClass: "sidebar-menu-all-title" }, [
                    _vm._v("전체 메뉴"),
                  ]),
                  _vm._l(_vm.permission_routers, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "sidebar-my-menu-item-all" },
                      [
                        _c(
                          "div",
                          { staticClass: "sidebar-my-menu-title-all-icon" },
                          [
                            _c(
                              "i",
                              {
                                class: [
                                  "material-icons sidebar-my-menu-icon-all",
                                  _vm.getIcons(item.icon, "color"),
                                ],
                              },
                              [_vm._v(_vm._s(_vm.getIcons(item.icon, "icon")))]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "sidebar-my-menu-title-all" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "shortcut_a",
                                on: {
                                  click: function ($event) {
                                    return _vm.goMobilePage(item.href)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "sidebar-my-menu-title-book" },
                          [
                            _c(
                              "i",
                              {
                                class: [
                                  "material-icons sidebar-my-menu-icon-book",
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.mobileBookmark(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.mobileBookmarkIcon(item)))]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }