var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.reseted
    ? _c("div", [
        _c(
          "video",
          { ref: "video", staticClass: "video-js vjs-big-play-centerd" },
          _vm._l(_vm.trackList, function (crtTrack, index) {
            return _c("track", {
              key: index,
              attrs: {
                kind: crtTrack.kind,
                label: crtTrack.label,
                src: crtTrack.src,
                srcLang: crtTrack.srcLang,
                default: crtTrack.default,
              },
            })
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }