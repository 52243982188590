var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-select", {
        ref: "selectbox",
        class: [
          _vm.stype === "tableselect"
            ? "customTableSelect"
            : _vm.stype === "tableHeaderselect"
            ? "customTableHeaderSelect"
            : "customSelect",
        ],
        attrs: {
          options: _vm.items,
          dense: _vm.dense,
          clearable: _vm.clearable,
          label: _vm.label,
          color: "orange-7",
          behavior: "dialog",
          rules: [_vm.myRule],
          disable: _vm.disabled || !_vm.editable,
        },
        on: { input: _vm.input },
        scopedSlots: _vm._u(
          [
            _vm.label
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            [
                              !_vm.required
                                ? _c("i", {
                                    staticClass:
                                      "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                                  })
                                : _vm._e(),
                              _c("font", { staticClass: "formLabelTitle" }, [
                                _vm._v(_vm._s(_vm.label)),
                              ]),
                              _vm.required
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons labelfrontIcon text-requiredColor",
                                    },
                                    [_vm._v("check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "option",
              fn: function (scope) {
                return [
                  _c(
                    "q-item",
                    _vm._g(
                      _vm._b({}, "q-item", scope.itemProps, false),
                      scope.itemEvents
                    ),
                    [
                      _c(
                        "q-item-section",
                        [
                          _vm.isShowLabel
                            ? _c("q-item-label", [
                                _vm._v(_vm._s(scope.opt.label)),
                              ])
                            : _c("q-item-label", [
                                _vm._v(_vm._s(scope.opt.anotherLabel)),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.vValue,
          callback: function ($$v) {
            _vm.vValue = $$v
          },
          expression: "vValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }