var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-btn-group",
    { attrs: { outline: "" } },
    [
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBtnApprRetrieve && _vm.editable,
            expression: "isBtnApprRetrieve&&editable",
          },
        ],
        attrs: {
          flat: _vm.flat,
          label: "결재회수",
          icon: "refresh",
          color: "red",
        },
        on: { btnClicked: _vm.apprRetrieve },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBtnApprHis,
            expression: "isBtnApprHis",
          },
        ],
        attrs: { flat: _vm.flat, label: "결재이력", icon: "history" },
        on: { btnClicked: _vm.apprHistoryPop },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBtnApprReReq,
            expression: "isBtnApprReReq",
          },
        ],
        attrs: {
          flat: _vm.flat,
          label: "재상신",
          icon: "check",
          color: "purple",
        },
        on: { btnClicked: _vm.apprReturnSave },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBtnApprReq,
            expression: "isBtnApprReq",
          },
        ],
        attrs: {
          flat: _vm.flat,
          isSubmit: _vm.approvalInfo.isApproval,
          url: _vm.approvalInfo.approvalUrl,
          param: _vm.approvalInfo.param,
          mappingType: "PUT",
          label: "결재요청",
          icon: "check",
          color: "purple",
        },
        on: { beforeAction: _vm.beforeAction, btnCallback: _vm.btnCallback },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupApprOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }