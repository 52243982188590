<template>
  <div class="alimqr">
    <q-btn
      flat
      dense
      color="white"
      class="topnoti"
      icon="account_circle"
      @click="openUserInfo">
    </q-btn>
    <q-dialog v-model="dialogUser" position="top">
      <q-card>
        <q-card-section class="row user-info-popup">
          <div class="col-12 text-userinfo">
            <div class="userinfo-username"><span class="material-icons-outlined">account_circle</span> <span v-text="userName"></span></div>
            <div class="userinfo-userdept"><span class="material-icons-outlined">business</span> <span v-text="userDeptName"></span></div>
            <q-btn flat @click="closeUserPopup" round dense icon="close" class="userinfo-close">
            </q-btn>
          </div>
          <div class="col-6 text-usersetting">
            <q-btn flat  color="red" @click="logout">
              <q-icon left size="3em" name="logout" />
              <div>로그아웃</div>
            </q-btn>
          </div>
          <div class="col-6 text-usersetting">
            <q-btn flat color="secondary" @click="changePassword">
              <q-icon left size="3em" name="settings" />
              <div>비밀번호 변경</div>
            </q-btn>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  components: {
  },
  data: () => ({
    dialogUser: false,
    position: 'top',
    userName: '',
    userDeptName: '',
    popupOptions: {
      position: 'bottom',
      target: null,
      title: '',
      visible: false,
      width: '100%',
      isFull: false,
      param: {},
      closeCallback: null,
    },
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.userName = this.$store.getters.user.userName;
      this.userDeptName = this.$store.getters.user.deptName;
    },
    openUserInfo() {
      this.dialogUser = true
    },
    closeUserPopup() {
      this.dialogUser = false
    },
    logout() {   
      this.$http.url = transactionConfig.auth.login.logout.url;
      this.$http.type = 'POST';
      this.$http.request(
        this.$store.dispatch('LogOut').then(() => {
          window.sessionStorage.removeItem('defaultPlantCd');
          location.reload();// In order to re-instantiate the vue-router object to avoid bugs
        })
      );
    },
    changePassword() {
      this.dialogUser = false;
      this.popupOptions.title = '비밀번호 변경'; // 비밀번호 변경
      this.popupOptions.target = () => import(`${'@/pages/common/user/changePw.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="scss">
.user-info-popup {
  height: 270px;
}
.userinfo-username {
  font-size: 3em;
  font-weight: 600;
}
.userinfo-userdept {
  font-size: 1.5em;
  font-weight: 500;
}
.text-usersetting {
  text-align: center;
  height: 30px !important;
}
.text-userinfo {
  text-align: center;
  background-color: #dddddd;
  border-radius: 10px;
  padding-top:30px !important;
}
.userinfo-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
}
</style>
