<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div class="td-input-column">
    <div v-if="!isShow" class="pad-txt-label">
      <div 
        tabindex="0" 
        :class="[disabled ? '' : 'table-td-textlabel', colorClass? colorClass: '']"
        v-html="$comm.convertEnter(props.row[col.name])"
        @focus="textfocus()"
        @click="textfocus()" >
      </div>
    </div>
    <div v-if="editable&&isShow">
      <q-input
        ref="input-column"
        class="table-td-text-grid"
        type="textarea"
        outlined autofocus
        :autogrow="true"
        :disable="disabled"
        v-model="props.row[col.name]"
        @input="datachange"
        @keydown.enter.exact.prevent
        @blur="textfocusout()"
      ></q-input>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
export default {
  /* attributes: name, components, props, data */
  name: 'c-text-column',
  props: {
    name: {
      type: String,
    },
    colorClass: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    col: {
      type: Object,
      default: function() {
        return {
          taskClassCd: '',
          taskKey: '',
        }
      }
    },
    props: {
      type: Object,
      default: function() {
        return { }
      }
    },
  },
  data() {
    return {
      isShow: '',
    };
  },
  computed: {
  },
  watch: {
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // url setting
    },
    textfocus() {
      if (this.editable&&!this.disabled) {
        this.$set(this.$data, 'isShow', true)
      }
    },
    textfocusout() {
      this.$set(this.$data, 'isShow', false)
    },
    datachange() {
      this.$emit('datachange')
    }
  },
};
</script>