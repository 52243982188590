<!--
  목적 : 라벨 타이틀과 값 표시 컴포넌트
  작성자 : ldy
  Detail :
  *
  examples:
  *
  -->
<template>
  <div class="fix-height">
      <div class="row items-center all-pointer-events">
        <b>
          <i class="pe-7s-comment lblIcon"></i>
          <font class="lbltitle">{{title}}</font>
        </b>
      </div>
      <div class="lblvalue" :style="setStyle" v-html="convertValue"></div>
  </div>
</template>

<script>
export default {
  name: 'c-label-text',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    row: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    setStyle() {
      let returnStyle = '';
      let height = this.row > 0 ? String(22 * this.row) + 'px' : 'auto';
      returnStyle = 'max-height: ' + height + ';min-height: ' + height + ';';
      let fontColor = !(this.row > 0) ? 'color: #2196F3;' : '';
      returnStyle += fontColor;
      return returnStyle
    },
    convertValue() {
      let vValue = this.value
      if (vValue) {
        vValue = vValue.replace(/\n/g, '<br/>');
      }
      return vValue;
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {},
  beforeDestory() {
    this.init();
  },
  /** methods **/
  methods: {
    init() {
      // TODO : 여기에 초기 설정용 함수를 호출하거나 로직을 입력하세요.
    },
  },
};
</script>
<style lang="scss">
.lblvalue {
  padding: 2px 8px !important;
  height: 100% !important;
  word-wrap: break-word;
  border-bottom: 1px solid rgba(0,0,0,0.3);
  overflow-y: auto;
  font-size: 1.05em;
}
.lbltitle {
  font-size: 0.8rem !important;
}
.lblIcon {
  font-size: 0.7rem !important;
  margin-right: 4px;
  font-weight: 600;
}
</style>
