var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-field", {
        staticClass: "customTag",
        attrs: {
          "stack-label": "",
          "bottom-slots": "",
          color: "orange-7",
          dense: _vm.dense,
          label: _vm.label,
          disable: _vm.disabled || !_vm.editable,
          rules: [_vm.myRule],
        },
        scopedSlots: _vm._u(
          [
            _vm.label
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            [
                              _vm.required
                                ? _c("i", {
                                    staticClass:
                                      "pe-7s-note labelfrontIcon text-requiredColor",
                                  })
                                : _vm._e(),
                              !_vm.required
                                ? _c("i", {
                                    staticClass: "pe-7s-note labelfrontIcon",
                                  })
                                : _vm._e(),
                              _c("font", { staticClass: "formLabelTitle" }, [
                                _vm._v(_vm._s(_vm.label)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "control",
              fn: function () {
                return [
                  _vm._l(_vm.items, function (item, idx) {
                    return _c("q-chip", {
                      key: idx,
                      attrs: {
                        outline: "",
                        square: "",
                        dense: _vm.dense,
                        removable: _vm.editable && !_vm.disabled,
                        clickable: _vm.clickable,
                        color: "primary",
                        "text-color": "white",
                        icon: _vm.icon,
                        label: item[_vm.itemText],
                        title: item[_vm.itemText],
                      },
                      on: {
                        remove: (state) => _vm.remove(state, item),
                        click: (e) => _vm.clickTag(item),
                      },
                      model: {
                        value: item[_vm.itemValue],
                        callback: function ($$v) {
                          _vm.$set(item, _vm.itemValue, $$v)
                        },
                        expression: "item[itemValue]",
                      },
                    })
                  }),
                  _vm.editable && !_vm.disabled
                    ? _c("q-chip", {
                        attrs: {
                          outline: "",
                          square: "",
                          dense: _vm.dense,
                          clickable: "",
                          color: "orange",
                          "text-color": "white",
                          icon: "add",
                          label: _vm.addLabel,
                          title: _vm.addLabel,
                        },
                        on: { click: _vm.add },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.items,
          callback: function ($$v) {
            _vm.items = $$v
          },
          expression: "items",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }