var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-container_foreground", style: _vm.cssVariables },
    [
      _c(
        "div",
        { staticClass: "btn-container" },
        [
          _vm._l(_vm.localOptions, function (button, index) {
            return _c(
              "div",
              {
                key: `label-${index}`,
                class: {
                  [`btn-item-${index} labels`]: true,
                  ["checked"]: button.isActive,
                  ["unchecked"]: !button.isActive,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleLabelClick(button)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "active-label" },
                  [
                    button.badge
                      ? _c("div", { staticClass: "btn-badge" }, [
                          _vm._v(" " + _vm._s(button.badge) + " "),
                        ])
                      : _vm._e(),
                    _vm._t(
                      "icon",
                      function () {
                        return [
                          _c(
                            "i",
                            { staticClass: "material-icons bottom-bar-icon" },
                            [_vm._v(_vm._s(button.icon))]
                          ),
                        ]
                      },
                      { props: button }
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "btn-title" },
                  [
                    _vm._t(
                      "title",
                      function () {
                        return [_vm._v(" " + _vm._s(button.title) + " ")]
                      },
                      { props: button }
                    ),
                  ],
                  2
                ),
                _vm.hasChild(button)
                  ? _c(
                      "div",
                      {
                        class: {
                          ["btn-super-parent"]: button.isActive,
                          ["btn-class-showable"]: _vm.showable,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "btn-child-parent" },
                          _vm._l(button.childs || [], function (child, idx) {
                            return _c(
                              "div",
                              {
                                key: idx,
                                staticClass: "btn-child",
                                class: child.color,
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleChildClick(child)
                                  },
                                },
                              },
                              [
                                _vm._t(
                                  "child-icon",
                                  function () {
                                    return [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons bottom-bar-icon",
                                        },
                                        [_vm._v(_vm._s(child.icon))]
                                      ),
                                      _vm._t(
                                        "child-title",
                                        function () {
                                          return [
                                            _vm._v(
                                              " " + _vm._s(child.title) + " "
                                            ),
                                          ]
                                        },
                                        { props: child }
                                      ),
                                    ]
                                  },
                                  { props: child }
                                ),
                                child.badge
                                  ? _c(
                                      "div",
                                      { staticClass: "btn-child-badge" },
                                      [_vm._v(" " + _vm._s(child.badge) + " ")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasActiveClass,
                  expression: "hasActiveClass",
                },
              ],
              attrs: { id: "sweep" },
            },
            [
              _c("div", { attrs: { id: "sweep-right" } }),
              _c("div", { attrs: { id: "sweep-center" } }),
              _c("div", { attrs: { id: "sweep-left" } }),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }