/**
 * 모바일 전용 config
 */
 let mobileConfig = {
  main: {
    list: {
      url: '/api/main/mobile/lists',
    }
  },
  sys: {
    favorite: {
      mobile: {
        list: {
          url: "/api/sys/favorites/mobile"
        },
        save: {
          url: "/api/sys/favorites/mobile"
        },
        insert: {
          url: "/api/sys/favorite/mobile"
        },
        delete: {
          url: "/api/sys/favorite/mobile"
        },
      },
    },
  },
  sai: {
    tbm: {
      get: {
        mobile: {
          url: "/api/sai/tbm/mobile/{0}/{1}"
        },
      },
      update: {
        signature: {
          url: "/api/sai/tbm/signature"
        }
      },
    }
  },
  sop: {
    cto: {
      basic: {
        getChecklist: {
          url: "/api/sop/cto/info/checklist"
        }
      }
    },
    fft: {
      fireFightingCheck: {
        target: {
          get: {
            url: "/api/sop/fft/fireFightingCheck/target/{0}"
          },
          insert: {
            url: "/api/sop/fft/fireFightingCheck/target"
          },
          update: {
            url: "/api/sop/fft/fireFightingCheck/target"
          },
          delete: {
            url: "/api/sop/fft/fireFightingCheck/target"
          },
        }
      }
    },
    min: {
      equipment: {
        plan: {
          daily: {
            get: {
              mobile: {
                url: "/api/sop/min/equipment/daily/plan/mobile"
              },
              equipment: {
                url: "/api/sop/min/equipment/daily/plan/equipment/{0}"
              },
              improve: {
                url: "/api/sop/min/equipment/daily/plan/improves"
              },
            }
          },
          deterior: {
            get: {
              mobile: {
                url: "/api/sop/min/equipment/deterior/plan/mobile"
              },
              equipment: {
                url: "/api/sop/min/equipment/deterior/plan/equipment/{0}"
              }
            },
          }
        },
        result : {
          daily: {
            resultFind: {
              url: "/api/sop/min/equipment/daily/result/find"
            }
          },
          deterior: {
            resultFind: {
              url: "/api/sop/min/equipment/deterior/result/find"
            }
          },
          lunar: {
            url: "/api/sop/min/equipment/lunar/{0}"
          }
        },
      },
    },
    edu: {
      plan: {
        update: {
          targetUser: {
            signature: {
              url: "/api/sop/edu/result/targetuser/signature"
            }
          }
        },
      },
      result: {
        get: {
          mobile: {
            url: "/api/sop/edu/result/mobile/{0}/{1}"
          }
        },
      },
    },
  }  
};

export default mobileConfig;
