<!--
  TODO : 버튼 공통 모듈
-->
<template>
  <q-btn-group outline >
    <!-- 회수 -->
    <c-btn
      :flat="flat"
      v-show="isBtnApprRetrieve&&editable"
      label="결재회수"
      icon="refresh"
      color="red"
      @btnClicked="apprRetrieve" />
    <c-btn
      :flat="flat"
      v-show="isBtnApprHis"
      label="결재이력"
      icon="history"
      @btnClicked="apprHistoryPop" />
    <c-btn
      :flat="flat"
      v-show="isBtnApprReReq"
      label="재상신"
      icon="check"
      color="purple"
      @btnClicked="apprReturnSave" />
    <c-btn
      :flat="flat"
      v-show="isBtnApprReq"
      :isSubmit="approvalInfo.isApproval"
      :url="approvalInfo.approvalUrl"
      :param="approvalInfo.param"
      mappingType="PUT"
      label="결재요청"
      icon="check"
      color="purple"
      @beforeAction="beforeAction"
      @btnCallback="btnCallback" />
    <c-dialog :param="popupApprOptions"></c-dialog>
  </q-btn-group>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  /* attributes: name, components, props, data */
  name: 'c-appr-btn',
  props: {
    name: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    approvalInfo: {
      type: Object,
      default: function() {
        return {
          sysApprovalRequestId: '', // 결재요청번호
          /**
           * 결재상태코드
           * ASC0000001	결재중
           * ASC0000002	반려
           * ASC9999999	결재완료
           */
          approvalStatusCd: '', 
          apprEditable: false, // 결재버튼 활성화 기준
          param: null, // 결재 param
          approvalUrl: '', // 결재 url
          isApproval: false, // 결재 submit
          approvalParamValue: { // 결재상세화면 파라미터
            isApprContent: true
          },
          approvalRequestName: '', // 결재요청명 (문서 title)
          approvalConnId: '', // 결재연결용 업무일련번호 (문서 key)
        }
      },
    },
  },
  data() {
    return {
      popupApprOptions: {
        isFull: false,
        isApproval: true,
        width: '100%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
      lineList: [],
    };
  },
  computed: {
    isBtnApprHis() {
      return this.approvalInfo.sysApprovalRequestId;
    },
    isBtnApprReReq() {
      return this.editable && this.approvalInfo.approvalStatusCd == 'ASC0000002';
    },
    isBtnApprReq() {
      return this.editable && this.approvalInfo.apprEditable && !this.approvalInfo.sysApprovalRequestId;
    },
    isBtnApprRetrieve() {
      return this.editable && this.approvalInfo.apprEditable && (this.lineList && this.lineList.length === 1)
    },
  },
  watch: {
    'approvalInfo.sysApprovalRequestId'() {
      this.getLines();
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
  },
  /* methods */
  methods: {
    getLines() {
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      this.$http.url = selectConfig.sys.appr.line.list.url;
      this.$http.param = {
        sysApprovalRequestId: this.approvalInfo.sysApprovalRequestId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          let filterList = _result.data.filter( x => {
            return x.approvalStatusCd == 'ASC0000002' || x.approvalStatusCd == 'ASC9999999' 
          });
          if (filterList.length === 0) {
            this.lineList = this.$_.filter(_result.data, {approvalStatusCd: 'ASC0000000', approvalUserId: this.$store.getters.user.userId});
          }
        }
      },
      () => {
      });
    },
    beforeAction() {
      this.$emit('beforeApprAction');
    },
    btnCallback() {
      this.$emit('callbackApprAction');
    },
    apprHistoryPop() { // 결재이력 팝업
      this.popupApprOptions.isFull = false;
      this.popupApprOptions.width = '100%';
      this.popupApprOptions.title = '결재이력';
      this.popupApprOptions.param = {
        sysApprovalRequestId: this.approvalInfo.sysApprovalRequestId, // 결재요청코드
      };
      this.popupApprOptions.target = () => import(`${'@/pages/common/appr/apprHistoryPop.vue'}`);
      this.popupApprOptions.visible = true;
      this.popupApprOptions.closeCallback = this.closeApprHistoryPopup;
    },
    closeApprHistoryPopup() {
      this.popupApprOptions.target = null;
      this.popupApprOptions.visible = false;
    },
    apprReturnSave() { // 재상신 팝업
      this.popupApprOptions.isFull = true;
      this.popupApprOptions.width = '100%';
      this.popupApprOptions.title = '결재 재상신'; // 안전관찰 상세
      this.popupApprOptions.param = {
        sysApprovalRequestId: this.approvalInfo.sysApprovalRequestId,
      };
      this.popupApprOptions.target = () => import(`${'@/pages/sys/appr/apprRequest.vue'}`);
      this.popupApprOptions.visible = true;
      this.popupApprOptions.closeCallback = this.closeApprPopup;
    },
    apprRequestPop() { // 결재요청 팝업
      this.popupApprOptions.isFull = true;
      this.popupApprOptions.width = '100%';
      this.$set(this.approvalInfo.approvalParamValue, 'isApprContent', true);
      this.popupApprOptions.title = '결재요청';
      this.popupApprOptions.param = {
        approvalParamValue: this.approvalInfo.approvalParamValue, // // 결재상세화면 파라미터
        approvalTypeCd: this.approvalInfo.approvalTypeCd, // 결재유형코드
        approvalRequestName: this.approvalInfo.approvalRequestName, // 결재요청명
        approvalConnId: this.approvalInfo.approvalConnId, // 결재연결용 업무일련번호
      };
      this.popupApprOptions.target = () => import(`${'@/pages/sys/appr/apprRequest.vue'}`);
      this.popupApprOptions.visible = true;
      this.popupApprOptions.closeCallback = this.closeApprPopup;
    },
    closeApprPopup() {
      this.popupApprOptions.target = null;
      this.popupApprOptions.visible = false;
      this.$emit('requestAfterAction')
    },
    apprRetrieve() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청된 결재 건을 회수하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sys.appr.request.retrieve.url, this.approvalInfo.sysApprovalRequestId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('requestAfterAction')

            this.lineList = [];
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  },
};
</script>

