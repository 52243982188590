var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alimqr" },
    [
      _c("q-btn", {
        staticClass: "topnoti",
        attrs: { flat: "", dense: "", color: "white", icon: "account_circle" },
        on: { click: _vm.openUserInfo },
      }),
      _c(
        "q-dialog",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.dialogUser,
            callback: function ($$v) {
              _vm.dialogUser = $$v
            },
            expression: "dialogUser",
          },
        },
        [
          _c(
            "q-card",
            [
              _c("q-card-section", { staticClass: "row user-info-popup" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-userinfo" },
                  [
                    _c("div", { staticClass: "userinfo-username" }, [
                      _c("span", { staticClass: "material-icons-outlined" }, [
                        _vm._v("account_circle"),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.userName) },
                      }),
                    ]),
                    _c("div", { staticClass: "userinfo-userdept" }, [
                      _c("span", { staticClass: "material-icons-outlined" }, [
                        _vm._v("business"),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.userDeptName) },
                      }),
                    ]),
                    _c("q-btn", {
                      staticClass: "userinfo-close",
                      attrs: { flat: "", round: "", dense: "", icon: "close" },
                      on: { click: _vm.closeUserPopup },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 text-usersetting" },
                  [
                    _c(
                      "q-btn",
                      {
                        attrs: { flat: "", color: "red" },
                        on: { click: _vm.logout },
                      },
                      [
                        _c("q-icon", {
                          attrs: { left: "", size: "3em", name: "logout" },
                        }),
                        _c("div", [_vm._v("로그아웃")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 text-usersetting" },
                  [
                    _c(
                      "q-btn",
                      {
                        attrs: { flat: "", color: "secondary" },
                        on: { click: _vm.changePassword },
                      },
                      [
                        _c("q-icon", {
                          attrs: { left: "", size: "3em", name: "settings" },
                        }),
                        _c("div", [_vm._v("비밀번호 변경")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }