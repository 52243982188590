var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-application", attrs: { id: "benesApp" } },
    [
      _c(
        "div",
        { staticClass: "app-container app-theme-white" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in", appear: "" } },
            [_c("Header")],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in", appear: "" } },
            [_c("Sidebar")],
            1
          ),
          _c(
            "div",
            { staticClass: "app-main__outer" },
            [
              _c(
                "div",
                { staticClass: "app-main__inner p-1" },
                [
                  _vm._t("default", null, null, {
                    footerSearch: _vm.footerSearch,
                  }),
                ],
                2
              ),
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in", appear: "" } },
                [_c("Footer", { on: { closePopFooter: _vm.closePopFooter } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }