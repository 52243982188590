var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(_vm.layout, {
        tag: "component",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scopeProps) {
              return [
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [
                    _c("router-view", {
                      key: _vm.$route.fullPath,
                      attrs: { scopeProps: scopeProps },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.modeEnv
        ? _c("div", { staticClass: "envTxt" }, [_vm._v(_vm._s(_vm.modeEnv))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }