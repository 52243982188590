<!--
  목적 : 검색조건 컴포넌트
  작성자 : kdh
  Detail :
  *
  examples:
  *
  -->
<template>
  <div>
    <!-- 검색 -->
    <el-drawer
      v-show="isShowSearch"
      :visible.sync="visible"
      :append-to-body="true"
      :show-close="true"
      class="search-box-layer"
      title="검색조건"
      size="100%"
      direction="btt"
      @close="close">
      <q-card v-show="searchAreaShow" flat bordered class="default-card searchArea">
        <div class="row searchArearow"
          v-on:mouseover="onMouseOver"
          v-on:mouseout="onMouseOut"
          v-on:keyup.enter="onEnterKey"
        >
          <!-- 추가 검색 또는 사용자 정의 검색 slot -->
          <slot name="search"></slot>
        </div>
      </q-card>
      <div class="search-box-btn">
        <!-- <slot name="searchBtn">
        </slot> -->
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="search" />
        </q-btn-group>
      </div>
      <div class="popupBackBtn3">
        <q-btn flat color="white" text-color="white" icon="close" @click="close" />
      </div>
    </el-drawer>
    <q-card v-show="!isShowSearch" flat bordered class="default-card searchArea popsearchArea">
      <div class="row searchArearow"
        v-on:mouseover="onMouseOver"
        v-on:mouseout="onMouseOut"
        v-on:keyup.enter="onEnterKey"
      >
        <!-- 추가 검색 또는 사용자 정의 검색 slot -->
        <slot name="search"></slot>
      </div>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'c-search-box',
  props: {
    enter: {
      type: Boolean,
      default: true,
    },
    isShowSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMouseOver: false,
      visible: false,
    };
  },
  computed: {
    searchAreaShow() {
      return this.$store.state.app.searchAreaToggle;
    },
  },
  watch: {
    searchAreaShow() {
      this.visible = this.$_.clone(this.searchAreaShow)
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());

    if (this.enter) {
      window.addEventListener('keyup', this.onEnter);
    }

    this.init();
  },
  mounted() {},
  beforeDestroy() {
    if (this.enter) window.removeEventListener('keyup', this.onEnter);
  },
  methods: {
    init() {
    },
    onEnterKey() {
      this.$emit('enter');
      this.visible = false
    },
    onEnter(event) {
      if (event.which === 13 && this.isMouseOver && this.enter) {
        this.$emit('enter');
        this.visible = false
      }
    },
    onMouseOver() {
      this.isMouseOver = true;
    },
    onMouseOut() {
      this.isMouseOver = false;
    },
    close() {
      this.$store.dispatch('searchAreaToggle', false);
    },
    search() {
      this.$emit('enter');
      this.visible = false
    }
  },
};
</script>

<style lang="sass">
.search-box-layer
  .el-drawer
    border-radius: 16px 16px 16px 16px
    height: 70% !important
    width: calc(100vw - 16px) !important
    margin: 0 auto
    margin-bottom: 8px !important
  .searchArea
    padding: 20px 15px 0px 15px !important
    margin-bottom: 1rem
    border-bottom-width: 0px
    border-radius: 0px !important
  .searchArearow
    margin-top: 6px !important
  .el-icon-close
    margin-right: 10px

  .el-drawer__header
    padding: 0 !important
    margin: 0 !important
    background: #fff
    color: #6d6d6d
    font-weight: 600
    border-bottom-width: 0px
    height: 60px !important
    font-size: 1.3em
    span
      padding-left: 20px !important
    span:before
      font-family: "Material Icons" !important
      padding-right: 10px
      content: "\e876" !important
</style>
