var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-btn",
    {
      staticClass: "custom-btn",
      attrs: {
        align: "center",
        disable: _vm.disabled,
        loading: _vm.loading,
        outline: _vm.outline,
        size: _vm.size,
        flat: _vm.flat,
        color: _vm.getColors(),
        round: _vm.isIcon,
        "text-color": _vm.flat ? _vm.getFlatTextColors() : _vm.getTextColors(),
        label: _vm.label,
        icon: _vm.icon ? _vm.icon : void 0,
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.btnClicked.apply(null, arguments)
        },
      },
    },
    [_vm._t("tooltip")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }