<!--
목적 : 자재팝업 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customMaterial"
      class="customMaterial"
      :dense="dense"
      stack-label
      bottom-slots
      color="orange-7"
      :label="label"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'c-material',
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'name', // codename
    },
    plantCd: {
      type: String,
      default: '', //
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: String,
      default: 'single',
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.setValueText();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        this.$http.url = this.$format(selectConfig.mdm.mam.material.get.url, this.vValue);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (this.type == 'name') {
            this.valueText = _result.data.materialName;
          } else if (this.type == 'codename') {
            this.valueText = _result.data.materialCd + ' / ' +_result.data.materialName;
          }
        },
        () => {
        });
      } else {
        this.valueText = '';
      }
    },
    search() {
      this.popupOptions.title = '자재 목록';
      this.popupOptions.param = {
        type: this.multiple,
        plantCd: this.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.type == 'name') {
          this.valueText =data[0].materialName;
          this.$emit('username', data[0].materialName);
        } else if (this.type == 'codename') {
          this.valueText = data[0].materialCd + ' / ' +data[0].materialName;
          this.$emit('username', data[0].materialCd + ' / ' +data[0].materialName);
        }
        this.$emit('input', data[0].materialCd);
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('change', null);
    },
  },
};
</script>
<style lang="sass">
.customMaterial
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customMaterial:hover
  .dateCloseIcon
    display: block
.customMaterial.q-field--dense .q-field__control, .customMaterial.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customMaterial.q-field--filled .q-field__control
  border-bottom: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style>