var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-select", {
    ref: "multiSelect",
    class: [
      _vm.stype == "tableselect"
        ? "customMultiTableSelect"
        : "customMultiSelect",
    ],
    attrs: {
      multiple: "",
      "use-chips": "",
      "use-input": "",
      options: _vm.filterItems,
      dense: _vm.dense,
      clearable: _vm.clearable,
      label: _vm.label,
      "stack-label": "",
      rules: [_vm.myRule],
      disable: _vm.disabled || !_vm.editable,
      color: "orange-7",
      "option-disable": "disable",
    },
    on: { filter: _vm.filterFn, input: _vm.input, clear: _vm.clear },
    scopedSlots: _vm._u(
      [
        _vm.label
          ? {
              key: "label",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "row items-center all-pointer-events" },
                    [
                      _c(
                        "b",
                        [
                          !_vm.required
                            ? _c("i", {
                                staticClass:
                                  "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                              })
                            : _vm._e(),
                          _c("font", { staticClass: "formLabelTitle" }, [
                            _vm._v(_vm._s(_vm.label)),
                          ]),
                          _vm.required
                            ? _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons labelfrontIcon text-requiredColor",
                                },
                                [_vm._v("check")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "before-options",
          fn: function () {
            return [
              _c(
                "q-chip",
                {
                  staticClass: "q-ma-none customchip2",
                  attrs: {
                    outline: "",
                    square: "",
                    dense: "",
                    color: "blue-6",
                    "text-color": "white",
                    icon: "check",
                    clickable: true,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkEnd()
                    },
                  },
                },
                [_vm._v("선택완료 ")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "selected-item",
          fn: function (scope) {
            return [
              scope.opt
                ? [
                    _c(
                      "q-chip",
                      {
                        staticClass: "q-ma-none customchip",
                        attrs: {
                          outline: "",
                          square: "",
                          removable: scope.opt.disable ? false : true,
                          dense: "",
                          tabindex: scope.tabindex,
                          color: "orange-6",
                          "text-color": "white",
                        },
                        on: {
                          remove: function ($event) {
                            return scope.removeAtIndex(scope.index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.opt.label) + " ")]
                    ),
                  ]
                : _vm._e(),
            ]
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.vValue,
      callback: function ($$v) {
        _vm.vValue = $$v
      },
      expression: "vValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }