<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div class="fix-height" :class="[counter ? 'c-textarea-counter' : '']">
    <q-input
      :rows="rows"
      v-if="type==='normal'"
      bottom-slots
      :autogrow="autogrow"
      :label="label"
      type="textarea"
      :input-style="{minHeight: (23 * rows) + 'px', maxHeight: (23 * maxrows) + 'px'}"
      stack-label
      class="customFieldClass"
      :counter="counter"
      :maxlength="maxlength"
      :dense="dense"
      :prefix="prefix"
      :suffix="suffix"
      :readonly="readonly"
      color="orange-7"
      :mask="mask"
      fill-mask
      hide-bottom-space
      :rules="!required ? null : [val => !!val || '']"
      :disable="disabled || !editable"
      v-model="vValue"
      @input="input">
      <template v-slot:label>
        <div v-if="label" class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
            <q-checkbox
              v-if="isCheck"
              color="orange-7"
              :editable="editable"
              :disable="disabled || !editable"
              label="항목사용"
              true-value="O"
              false-value="X"
              name="click"
              v-model="checkValue"
              @input="checkboxClick"
            />
          </b>
        </div>
      </template>

      <template v-slot:before v-if="beforeIcon && beforeIcon.length > 0">
        <q-icon
          v-for="(icon, index) in beforeIcon"
          :key="index"
          :name="icon.name"
          :class="{ 'cursor-pointer' : icon.click }"
          @click="iconClick(icon)">
        </q-icon>
      </template>

      <template v-slot:prepend>
        <q-icon
          v-for="(icon, index) in prependIcon"
          :key="index"
          :name="icon.name"
          :class="{ 'cursor-pointer' : icon.click }"
          @click="iconClick(icon)">
        </q-icon>
      </template>

      <template v-slot:append>
        <q-icon v-if="close && !disabled && editable && !readonly" name="close" @click="closeClick" class="cursor-pointer textareaCloseIcon"></q-icon>
        <q-icon
          v-for="(icon, index) in appendIcon"
          :key="index"
          :name="icon.name"
          :class="{ 'cursor-pointer' : icon.click }"
          @click="iconClick(icon)">
        </q-icon>
      </template>

      <!-- <template v-slot:hint>
      </template> -->

      <template v-slot:after>
        <q-icon
          v-for="(icon, index) in afterIcon"
          :key="index"
          :name="icon.name"
          :class="{ 'cursor-pointer' : icon.click }"
          @click="iconClick(icon)">
        </q-icon>
      </template>
    </q-input>

    <q-card v-else style="width:100%" flat class="custom-textarea-editor">
      <q-card-section horizontal style="top:-14px;">
        <div class="q-field__label no-pointer-events ellipsis" style="line-height:15px; padding-bottom: 1px; font-size:12px; padding-left: 8px;">
          <div class="row items-center all-pointer-events">
            <b class="custom-textarea-label">
              <!-- <i class="pe-7s-note labelfrontIcon"></i> -->
              <font class="formLabelTitle2">{{label}}</font>&nbsp;
              <em v-if="required" class="q-px-sm bg-deep-orange text-white rounded-borders">필수</em>
            </b>
          </div>
        </div>
      </q-card-section>
      <q-card-section :style="{height: (editheight) + 'rem', maxHeight: (editheight) + 'rem', top: '0px'}"
        :class="{ 'cursor-pointer': (!disabled && editable), 'q-py-none': true}">
        <q-editor
          v-model="vValue"
          ref="editor"
          :min-height="editheightrem"
          autofocus
          :disable="disabled || !editable"
          @keyup.enter.stop
          @input="input"
          :definitions="definitions"
          :toolbar="[
            [
              {
                label: '정렬',
                fixedLabel: true,
                list: 'only-icons',
                options: ['left', 'center', 'right', 'justify']
              },
            ],
            ['bold', 'italic', 'strike', 'underline'],
            ['token'],
            ['hr'],
            [
              {
                label: '폰트크기',
                fixedLabel: true,
                fixedIcon: true,
                list: 'no-icons',
                options: [
                  'size-1',
                  'size-2',
                  'size-3',
                  'size-4',
                  'size-5',
                  'size-6',
                  'size-7'
                ]
              },
            ],
            ['undo', 'redo'],
            ['insert_img'],
            ['viewsource'],
            ['fullscreen'],
          ]"
        >
        <template v-slot:token>
          <q-btn-dropdown dense no-caps ref="token" no-wrap unelevated color="white" text-color="primary" label="폰트 Color" size="sm">
            <q-list>
              <q-item tag="label" clickable @click="color('backColor', highlight)">
                <q-item-section side>
                  <q-icon name="highlight"><q-tooltip>배경색</q-tooltip></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-color v-model="highlight" default-view="palette" no-header no-footer class="my-picker" />
                </q-item-section>
              </q-item>
              <q-item tag="label" clickable @click="color('foreColor', foreColor)">
                <q-item-section side>
                  <q-icon name="format_paint"><q-tooltip>폰트색</q-tooltip></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-color v-model="foreColor" no-header no-footer default-view="palette" class="my-picker" />
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </template>
        </q-editor>
      </q-card-section>
      <!-- <q-separator color="grey-5" /> -->
    </q-card>
  </div>
</template>

<script>
export default {
  /* attributes: name, components, props, data */
  name: 'c-textarea',
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Number, String],
      default: null,
    },
    type: {
      type: String,
      default: 'normal', // normal editor
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    // 자동 높이
    autogrow: {
      type: Boolean,
      default: true,
    },
    maxlength: {
      type: Number,
    },
    beforeIcon: {
      type: [String, Array],
    },
    prependIcon: {
      type: [String, Array],
    },
    appendIcon: {
      type: [String, Array],
    },
    afterIcon: {
      type: [String, Array],
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 1,
    },
    editheight: {
      type: Number,
      default: 30,
    },
    maxrows: {
      type: Number,
      default: 8,
    },
    isCheck: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      foreColor: '#000000',
      highlight: '#ffff00aa',
      editheightrem: '',
      vValue: '',
      checkValue: 'X',
      definitions: {
        insert_img: {
          tip: '사진 첨부',
          label: '사진넣기',
          icon: 'photo',
          handler: this.insertImg 
        },
        viewsource: {
          tip: 'HTML 보기',
        },
        fullscreen: {
          tip: '전체화면 보기',
        },
        hr: {
          tip: '구분선 삽입',
        },
      }
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모 v-model 값이 변경되었을 경우 바인딩
    value() {
      this.vValue = this.value;
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.vValue = this.value;
    this.editheightrem = (this.editheight - 5) + 'rem'
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    input() {
      // TODO : 부모에게 변경여부 전달
      this.$emit('input', this.vValue);
      this.$emit('dataChange', this.vValue);
    },
    insertImg() { 
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = '.png, .jpg, .gif'
      let file
      input.onchange = () => {
        const files = Array.from(input.files)
        file = files[0]
        const reader = new FileReader()
        let dataUrl = ''
        reader.onloadend = () => {
          dataUrl = reader.result
          this.vValue += '<div><img style="max-width: 800px;" src="' + dataUrl + '" /></div>' 
          this.$emit('input', this.vValue);
        }
        reader.readAsDataURL(file)
      }
      input.click()
    },
    color (cmd, name) {
      const edit = this.$refs.editor
      this.$refs.token.hide()
      edit.caret.restore()
      edit.runCmd(cmd, name)
      edit.focus()
    },
    /**
     * iconClick시 부모에게 emit한다.
     */
    iconClick(_item) {
      if (!_item.callbackName) {
        return ;
      }
      this.$emit(_item.callbackName, _item);
    },
    checkboxClick() {
      this.$emit('checkboxClick', this.checkValue)
    },
    /**
     * 입력값 초기화
     */
    closeClick() {
      this.vValue = '';
      this.$emit('input', this.vValue);
      this.$emit('dataChange', this.vValue);
    },
  },
};
</script>
<!-- <style lang="sass">
.customTextArea
  margin-top: 20px !important
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8em
  .q-field__append
    padding-top: 0px
    font-size: 1.05em
  .textareaCloseIcon
    display: none
  .q-field__marginal
    height: 38px !important
  .q-field__control
    min-height: 38px !important
    font-size: 1.15em
  .q-field__control-container
    padding: 0px !important
  textarea.q-field__native
    margin-top: 5px !important
    line-height: 23px !important
.customTextArea:hover
  .textareaCloseIcon
    display: block

.customTextArea.q-field--filled .q-field__control
  border-bottom: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

// .q-editor__content
//   max-width: 55rem
//   max-height: 25rem
.my-picker .q-color-picker__cube
  padding: 10px !important
  
.c-textarea-counter 
  .q-field__bottom
    display: flex

.customTextArea.q-field--disabled 
  .q-field__control
    pointer-events: auto !important
</style> -->