<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div v-if="isUploaderSetting || isMultiTask" class="fix-height row upload-picture-cover">
    <div class="col-12">
      <q-uploader
        flat bordered with-credentials
        :ref="uploadRef"
        color="custom-upload-color"
        class="custom-upload-picture"
        capture="environment"
        field-name="file" 
        :url="url"
        :headers="headers"
        method="POST"
        :label="label"
        :form-fields="formFields"
        :auto-upload="false"
        :hide-upload-btn="true"
        :multiple="uploaderSetting.multipleSelFlag === 'Y'"
        :accept="uploaderSetting.acceptExt"
        :max-file-size="uploaderSetting.limitSize * 1000000"
        :max-total-size="uploaderSetting.limitSize * 1000000 * uploaderSetting.limitCnt"
        :max-files="maxFiles"
        :no-thumbnails="uploaderSetting.previewFlag === 'Y'"
        :style="uploadStyle"
        @added="added"
        @finish ="finish"
        @failed="failed"
        @rejected="rejected"
      >
        <!-- :disable="!editable" -->
        <!-- :filter="filter" -->
        <template v-slot:header>
        </template>
        <!-- <template v-slot:list="scope"> -->
        <template v-slot:list>
          <div 
            v-if="fileList&&fileList.length > 0"
            class="update-picture-card"
          >
            <q-img
              :src="fileList[previewImageIndex].imgUrl"
              spinner-color="white"
              contain
              class="upload-picture"
            />
            <div class="info">
              <!-- 이전 사진 -->
              <q-btn 
                v-if="fileList && fileList.length > 1"
                unelevated round dense
                size="10px"
                color="grey" 
                icon="chevron_left"
                @click="beforePreView">
              </q-btn>
              <!-- 확장 -->
              <q-btn 
                unelevated round dense
                size="10px"
                color="primary" 
                icon="fullscreen"
                @click="allImagePreView">
              </q-btn>
              <!-- 삭제 -->
              <q-btn 
                v-if="editable&&!isMultiTask"
                unelevated round dense
                size="10px"
                color="negative" 
                icon="delete_outline"
                @click="removeFile(fileList[0])">
              </q-btn>
              <!-- 다음 사진 -->
              <q-btn 
                v-if="fileList && fileList.length > 1"
                unelevated round dense
                size="10px"
                color="grey" 
                icon="navigate_next"
                @click="afterPreView">
              </q-btn>
            </div>
            <template v-if="editable&&!isMultiTask">
              <q-uploader-add-trigger />
            </template>
          </div>
          <!-- <template v-else-if="!scope.files || scope.files.length === 0"> -->
          <template v-else>
            <div class="empty_dscription">
              <div style="text-align: center; padding:5px" v-show="editable&&!isMultiTask">
                <!-- <q-chip square icon="info" style="width:100%">
                  Drag or Click
                </q-chip> -->
                <div style="text-align: center">
                  <q-icon size="35px" name="file_upload" />
                </div>
                <div style="text-align: center">
                  사진을 올리세요
                </div>
              </div>
              <template v-if="editable&&!isMultiTask">
                <q-uploader-add-trigger />
              </template>
            </div>
          </template>
        </template>
      </q-uploader>
    </div>
    
    <!-- 이미지 미리보기 -->
    <q-dialog v-if="preview&&(preview.src || (preview.fileList && preview.fileList.length > 0))" 
      v-model="preview.isShow">
      <q-card class="preview-image-card">
        <q-btn color="white" text-color="primary" @click="preview.isShow = false" round dense icon="close" class="preview-close-btn">
          <!-- 창닫기 -->
          <q-tooltip class="bg-white text-primary">창닫기</q-tooltip>
        </q-btn>
        <q-carousel
          v-if="preview.fileList && preview.fileList.length > 0"
          swipeable animated thumbnails infinite
          v-model="preview.slide"
          @transition="transitionImage"
        >
          <q-carousel-slide
            v-for="(file, idx) in preview.fileList"
            :key="idx"
            :name="file.sysAttachFileId" 
            :img-src="file.src"></q-carousel-slide>
        </q-carousel>
        <q-img v-else :src="preview.src"></q-img>
        <q-card-section>
          <q-btn
            fab
            color="primary"
            icon="save_alt"
            class="absolute"
            style="top: 0; right: 12px; transform: translateY(-50%);"
            @click.prevent="fileDown(preview)"
          ></q-btn>
          <div class="row no-wrap items-center">
            <div class="col text-h6">
              {{preview.oriFileNm}}
            </div>
            <div class="col-auto text-grey text-caption q-pt-md row no-wrap items-center">
              {{ getFileSizeTextByRound(preview.fileSize) }}
            </div>
          </div>
        </q-card-section>
        <q-separator v-if="preview.description"></q-separator>
        <q-card-section v-if="preview.description" class="q-pt-none">
          <div class="text-subtitle1">
            <q-icon name="info"></q-icon>  
            <!-- 파일 설명 -->
            {{'파일 설명'}}
          </div>
          <div class="text-caption text-grey">
            {{preview.description}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import backendConfig from '@/js/backendConfig.js';
import { getAccessToken } from '@/utils/auth';
import mixinUpload from './js/mixin-upload'
export default {
  /* attributes: name, components, props, data */
  name: 'c-upload-picture',
  mixins: [mixinUpload],
  props: {
    name: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '첨부파일', // 첨부파일
    },
    height: {
      type: String,
      default: '100px',
    },
    attachInfo: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: '',
          taskKey: '',
          isRev: false,
          task: []
        }
      }
    },
    imageRestriction: {
      type: Object,
      default: function() {
        return { 
          width: 0,
          height: 0,
        }
      }
    },
    isMultiTask: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadRef: 'custom-upload',
      preview: {
        slide: 1,
        isShow: false,
        sysAttachFileId: '',
        src: '',
        oriFileNm: '',
        fileSize: 0,
        description: '',
        fileExt: '',
        fileList: [],
      },
      isUploaderSetting: false,
      uploaderSetting: {
        multipleSelFlag: 'N',
        previewFlag: 'N',
        acceptExt: '*',
        limitSize: 1048576,
        limitCnt: 5,
        noThumbnails: false,
        explainFlag: 'N',
        resizeWidth: 0,
        resizeQuality: 1
      },
      previewImageIndex: 0,
      fileList: [],
      uploderSettingUrl: '',
      uploadedFilesUrl: '',
      tempTaskKeyUpdateUrl: '',
      allDeleteUrl: '',
      deleteUrl: '',
      previewUrl: '',
      fileDownUrl: '',
    };
  },
  computed: {
    url() {
      return backendConfig.getUrl(transactionConfig.com.upload.uploading.url);
    },
    headers() {
      return [
        { name: 'X-Authorization', value: getAccessToken(), }
      ];
    },
    formFields() {
      let data = [
        {
          name: 'regUserId',
          value: this.$store.getters.user.userId,
        },
        {
          name: 'chgUserId',
          value: this.$store.getters.user.userId,
        }
      ];
      if (this.attachInfo) {
        if (this.attachInfo.taskClassCd) {
          data.push({
            name: 'taskClassCd',
            value: this.attachInfo.taskClassCd,
          })
        }
        /**
         * 신규인 경우 taskKey가 없을 수 있음
         * 해당의 경우 api-server에서 유니크한 id를 생성하여 저장 후 반환한다.
         */
        if (this.attachInfo.taskKey) {
          data.push({
            name: 'taskKey',
            value: this.attachInfo.taskKey,
          })
        } else {
          let val = this.attachInfo.taskClassCd + '_' + uid();
          data.push({
            name: 'taskKey',
            value: val,
          })
          this.$set(this.attachInfo, 'taskKey', val)
        }
      }
      return data;
    },
    maxFiles() {
      let fileCnt = this.fileList ? this.fileList.length : 0;
      return this.uploaderSetting.limitCnt - fileCnt
    },
    uploadStyle() {
      return `max-height:${this.height};min-height:${this.height};width: 100%;`
    },
    isTasks() {
      return this.attachInfo.task && this.attachInfo.task.length > 0
    }
  },
  watch: {
    'attachInfo.taskClassCd'() {
      this.setUploderSetting();
      this.getUploadedFiles();
    },
    'attachInfo.taskKey'() {
      this.getUploadedFiles();
    },
    'attachInfo.isSubmit'() {
      if (this.fileList && this.fileList.length > 0) {
        this.$_.forEach(this.fileList, file => {
          this.$set(file, 'taskKey', this.attachInfo.taskKey)
        })
      }
      // 첨부 저장하는 단계
      this.$http.url = this.tempTaskKeyUpdateUrl;
      this.$http.type = 'PUT';
      this.$http.param = this.fileList;
      this.$http.request((_result) => {
        if (_result.data) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.getUploadedFiles();
        }
      },);
    },
    'attachInfo.task': {
      handler: function () {
        this.getUploadedMultiTaskFiles();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // url setting
      this.uploderSettingUrl = selectConfig.sys.attach.get.url;
      this.uploadedFilesUrl = selectConfig.com.upload.fileList.url
      this.tempTaskKeyUpdateUrl = transactionConfig.com.upload.save.url
      this.allDeleteUrl = transactionConfig.com.upload.allDelete.url
      this.deleteUrl = transactionConfig.com.upload.delete.url
      this.previewUrl = selectConfig.com.upload.preview.url
      this.fileDownUrl = selectConfig.com.upload.fileDown.url
      
      this.setUploderSetting();
      if (!this.isMultiTask) {
        this.getUploadedFiles();
      } else {
        // multi 업무의 경우 첨부파일 업로드 정보를 따로 넣지 않음으로 인하여 높이 설정을 따로 하지 않음으로 init에서 설정하도록 처리
        setTimeout(() => {
          this.$refs['custom-upload'].$el.style.setProperty("--height-var", this.height);
        }, 150);
        this.getUploadedMultiTaskFiles();
      }
      this.setCompressOptions();
    },
    getFileSizeTextByRound(_fileByteSize) {
      return this.$comm.bytesToSize(_fileByteSize);
    },
    finish() {
      this.$refs['custom-upload'].removeUploadedFiles();
      this.$refs['custom-upload'].reset();
      this.reset();
      this.getUploadedFiles();
    },
    added(files) {
      if (this.queuedFileInfo.isUpload) return;
      // this.$refs['custom-upload'].queuedFiles
      this.compressCheck(files, this.uploaderSetting);
    },
    failed(info) {
      let message = '';
      if (info && info.files && info.files.length > 0) {
        message = '파일 [';
        let idx = 0;
        this.$_.forEach(info.files, file => {
          message += '"' + file.name + (idx !== (info.files.length - 1) ? '", ' : '"] ')
          idx++;
        });
        // 업로드에 실패하였습니다.
        message += '업로드에 실패하였습니다.'
      }
      this.$q.notify({
        color: 'negative',
        html: true,
        message: message,
        multiLine: true,
        timeout: 5000
      })
    },
    rejected(info) {
      if (!info || info.length === 0) {
        return;
      }
      let message = '';
      this.$_.forEach(info, reject => {
        // accept, max-file-size, max-total-size, filter, etc
        switch(reject.failedPropValidation) {
          case 'max-file-size': // 파일용량 초과
          case 'max-total-size': // 파일 전체 용량 초과
            // '첨부하신 "' + reject.file.name + '"의 용량이 지정된 용량보다 큽니다. (지정된 용량 : 10MB, 파일 용량 : ' + this.getFileSizeTextByRound(reject.file.size) + ')';
            message +=  '첨부하신 "' + reject.file.name + '"의 용량이 지정된 용량보다 큽니다. (지정된 용량 : 10MB, 파일 용량 : ' + this.getFileSizeTextByRound(reject.file.size) + ')';
            break;
          case 'max-files': // 업로드 갯수 초과
            // '첨부하신 "' + reject.file.name + '"이 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다. (지정된 업로드 갯수 : 1)';
            message = '첨부하신 "' + reject.file.name + '"이 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다. (지정된 업로드 갯수 : 1)';
            break;
          case 'accept': // 확장자 맞지않음
            // '첨부하신 "' + reject.file.name + '"의 확장자가 올바르지 않습니다. (업로드 가능한 확장자 : xlsx)';
            message = '첨부하신 "' + reject.file.name + '"의 확장자가 올바르지 않습니다. (업로드 가능한 확장자 : xlsx)';
            break;
          case 'filter': // filter 걸린경우
          // 해당 기능 사용하지 않음으로 다국어 처리하지 않음
            message = '첨부하신 이미지 "' + reject.file.name + '"의 사이즈가 올바르지 않습니다. (사이즈 : ' + this.imageRestriction.width + ' X ' + this.imageRestriction.height + ')';
            break;
          default:
            break;
        }
      })
      this.$q.notify({
        color: 'negative',
        html: true,
        message: message,
        multiLine: true,
        timeout: 5000
      })
    },
    setUploderSetting() {
      if (!this.attachInfo.taskClassCd) return
      this.$http.url = this.$format(this.uploderSettingUrl, this.attachInfo.taskClassCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data) {
          this.isUploaderSetting = true;
          this.$_.extend(this.uploaderSetting, _result.data)

          // 높이 설정
          setTimeout(() => {
            this.$refs['custom-upload'].$el.style.setProperty("--height-var", this.height);
          }, 150);
        }
      },);
    },
    getUploadedFiles() {
      if (!this.attachInfo.taskClassCd || !this.attachInfo.taskKey) {
        this.$set(this.$data, 'fileList', [])
        return
      }
      this.$http.url = this.uploadedFilesUrl;
      this.$http.type = 'GET';
      this.$http.param = this.attachInfo;
      this.$http.request((_result) => {
        this.$set(this.$data, 'fileList', [])
        if (_result.data) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.fileList = _result.data;
          if (this.fileList && this.fileList.length > 0) {
            this.$_.forEach(this.fileList, item => {
              let encstr = item.previewImage;
              let url = window.URL || window.webkitURL;
              let blob = this.$comm.base64ToBlob(encstr, 'image/jpeg');
              let imgUrl = url.createObjectURL(blob);
              item.imgUrl = imgUrl;
            })
          }
        }
        this.$emit('files', this.fileList)
      },);
    },
    getUploadedMultiTaskFiles() {
      if (!this.isTasks) {
        this.$set(this.$data, 'fileList', [])
        return
      }
      this.$http.url = this.uploadedFilesUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        taskKeyClassCds: this.$_.map(this.attachInfo.task, item => {
          return `${item.taskKey}^${item.taskClassCd}`
        })
      }
      this.$http.request((_result) => {
        this.$set(this.$data, 'fileList', [])
        if (_result.data) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.fileList = _result.data;
          if (this.fileList && this.fileList.length > 0) {
            this.$_.forEach(this.fileList, item => {
              let encstr = item.previewImage;
              let url = window.URL || window.webkitURL;
              let blob = this.$comm.base64ToBlob(encstr, 'image/jpeg');
              let imgUrl = url.createObjectURL(blob);
              item.imgUrl = imgUrl;
            })
          }
        }
        this.$emit('files', this.fileList)
      },);
    },
    fileDown(file) {
      let thisVue = this;
      let accept = this.$comm.getAccept(file.fileExt);
      this.$http.url = this.fileDownUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sysAttachFileId: file.sysAttachFileId,
      };
      this.$http.request(
        _result => {
          let url = window.URL || window.webkitURL;
          let link = document.createElement('a');
          let blob = thisVue.$comm.base64ToBlob(_result.data, accept);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, file.oriFileNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = file.oriFileNm;
            link.click();
          }
        },
      );
    },
    haveImage() {
      if (this.fileList && this.fileList.length > 0) {
        let flag = false;
        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            flag = true;
            return false;
          }
        })
        return flag;
      } else {
        return false;
      }
    },
    setPreview(file, type) {
      if (!file) return;
      // 이미지인  경우
      if (file.sysAttachFileId) {
        if (type === 'single') {
          this.preview.fileList = [];
        }
        this.$http.url = this.previewUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          sysAttachFileId: file.sysAttachFileId,
        };
        this.$http.request(
          _result => {
            this.preview.isShow = true;
            this.preview.sysAttachFileId = file.sysAttachFileId;
            if (type === 'single' ) {
              this.preview.src = 'data:' + file.contentType + ';base64,' + _result.data ;
            } else {
              let curFile = this.$_.find(this.preview.fileList, { sysAttachFileId: file.sysAttachFileId });
              this.$set(curFile, 'src', ('data:' + file.contentType + ';base64,' + _result.data))
            }
            this.preview.oriFileNm = file.oriFileNm;
            this.preview.fileSize = file.fileSize;
            this.preview.description = file.description;
            this.preview.fileExt = file.fileExt;
          },
          _error => {
          }
        );
      }
    },
    allImagePreView() {
      if (this.fileList && this.fileList.length > 0) {
        this.preview.fileList = [];
        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            this.$set(file, 'src', file.imgUrl)
            this.preview.fileList.push(file)
          }
        })
        let firstFile = this.preview.fileList[0]
        this.preview.slide = firstFile.sysAttachFileId;
        this.preview.isShow = true;
        this.setPreview(firstFile, 'multi');
      } else {
        this.preview.fileList = [];
      }
    },
    transitionImage(val, oldVal) {
      let curFile = this.$_.find(this.preview.fileList, { sysAttachFileId: val });
      if (curFile) {
        this.setPreview(curFile, 'multi');
      }

      let preFile = this.$_.find(this.preview.fileList, { sysAttachFileId: oldVal });
      if (preFile) {
        this.$set(preFile, 'src', preFile.imgUrl)
      }
    },
    removeFile(file) {
      this.$http.url = this.$format(this.deleteUrl, file.sysAttachFileId);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: '삭제 완료',
            multiLine: true,
            timeout: 5000
          });
          this.getUploadedFiles();
        }
      },);
    },
    removeAllFiles() {
      this.$http.url = this.$format(this.allDeleteUrl, this.attachInfo.taskKey, this.attachInfo.taskClassCd);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: '삭제 완료',
            multiLine: true,
            timeout: 5000
          });
          this.getUploadedFiles();
        }
      },);
    },
    removeAllFilesMethod() {
      this.$http.url = this.$format(this.allDeleteUrl, this.attachInfo.taskKey, this.attachInfo.taskClassCd);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.getUploadedFiles();
        }
      },);
    },
    beforePreView() {
      if (this.previewImageIndex === 0) {
        this.$set(this.$data, 'previewImageIndex', (this.fileList.length - 1))
      } else {
        this.$set(this.$data, 'previewImageIndex', (this.previewImageIndex - 1))
      }
    },
    afterPreView() {
      if (this.previewImageIndex === (this.fileList.length - 1)) {
        this.$set(this.$data, 'previewImageIndex', 0)
      } else {
        this.$set(this.$data, 'previewImageIndex', (this.previewImageIndex + 1))
      }
    },
  },
};
</script>
<style lang="sass">
.custom-upload-picture
  // box-shadow: 0px 7px 10px rgba(black, 0.5) !important
  border-radius: 15px
  .upload-picture
    // width: 95px
    width: 100%
    // height: 95px
    height: calc(var(--height-var) - 5px)
    object-fit: fill
  .q-uploader__list
    padding: 0
    text-align: center
  .q-btn
    margin-top: 0px !important
    margin-left: 0px !important
.custom-upload-picture.q-uploader--disable
    min-height: var(--height-var) !important
    // min-height: 100px !important
.upload-picture-cover
  margin-top: -20px !important
  height: calc(var(--height-var) + 20px)
  // height: 120px
  padding-bottom: 20px !important

.q-uploader--disable
  max-height: 36px !important
  min-height: 20px !important

.empty_dscription
  font-size: 14px
  color: #909399
  margin-bottom: 0 !important

.file-origin-name
  line-height: 2em!important

.file-description
  .q-field--with-bottom
    padding-bottom: 0px !important
  .q-field--dense .q-field__inner
    padding-bottom: 0px !important

.preview-image-card 
  min-width: 800px
  .q-carousel 
    height:750px !important
.txtfileinfo
  padding-left: 0px !important
  word-break: break-all
  font-size: 12px
.nofileArea
  height: auto // 196px
  font-size: 0.95em !important

.before-file-list-toolbar
  min-height: 20px !important
  .q-toolbar__title
    font-size: 1em !important
.fixPreviewB
  font-size: 16px
  font-weight: 600
  text-align: center
  border: 1px solid #cccccc
  padding-top: 10px
  padding-bottom: 10px
  background: #f8f8f8
.preview-close-btn
  position: absolute
  right: 5px
  top: 5px
  z-index: 99


.update-picture-card
  // width: 95px
  width: 100%
  height: calc(var(--height-var) - 5px)
  // height: 95px
  position: relative
  transition: 0.4s ease-out
  &:hover
    &:before
      opacity: 1
    .info
      // display: block
      height: auto
      width: auto
      opacity: 1
      transform: translateY(-50px)
      transition: 0.5s ease-in
      button
        display: inline-flex
  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    border-radius: 15px
    background: rgba(black, 0.6)
    z-index: 2
    transition: 0.5s
    opacity: 0
  image
    width: 100%
    height: 100%
    object-fit: cover
    position: absolute
    top: 0
    left: 0
    border-radius: 15px
  .info
    // display: none
    height: 0
    width: 0
    position: relative
    z-index: 3
    color: white
    opacity: 0
    transform: translateY(-30px)
    transition: 0.5s ease
    button
      display: none
</style>