import '@firebase/messaging';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig  = {
    apiKey: "AIzaSyDpl6ej0MahbZgNezj8rzbPx89Hm35oZKM",
    authDomain: "shms-pwa.firebaseapp.com",
    projectId: "shms-pwa",
    storageBucket: "shms-pwa.appspot.com",
    messagingSenderId: "153035971819",
    appId: "1:153035971819:web:78dba6117589b68ca232ae",
    measurementId: "G-0K32SBP878"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

// 권한요청
// Notification.requestPermission()
//   .then((permission) => {
//     console.log('permission ', permission)
//     if (permission !== 'granted') {
//       // alert('알림을 허용해주세요')
//     }
//   })

let token = ''; 

// 토큰 받는곳
getToken(messaging, { vapidKey: 'BOZGs_YthBKS9kVe8Tgzjkl6yolZa_B0-nG08mog8M7TFu1BuzQ04BjmSUe4qF6iR55WovOH_EnnrHy3Q8S_CKo' }).then((currentToken) => {
  if (currentToken) {
      console.log('fcmToken: ', currentToken);
      token = currentToken;
  } else {
      console.log('No Instance ID token available. Request permission to generate one.');
  }
})

// 포그라운드 접근
onMessage(messaging, ((payload) => {
  console.log('foreground', payload);
    const title = payload.data.title;
    // const notification = new Notification(title, options); // 플랫폼 제한으로 안드로이드에선 지원되지 않음.
    /* eslint-disable */
    navigator.serviceWorker.ready.then((registration) => { 
      registration.showNotification(title, {
        body: payload.data.body,
        data: payload.data.route,
        tag: 'vibration-sample',
        badge: '/favicon.ico',
        icon: '/click.png',
        image: './nearAccident.jpg',
        // actions: [
        //   { title: '보기', action: 'goTab' },
        //   { title: '닫기', action: 'close' }
        // ]
      })
    })
    // navigator.serviceWorker.ready.then((registration) => {
    //   registration.getNotifications(options).then((notifications) => {
    //     console.log(notifications)
    //     // do something with your notifications
    //   });
    // }
    // )
    // return self.registration.showNotification(title, options);
}));

export {
    token,
    messaging,
};