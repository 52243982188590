var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-field", {
        ref: "customField",
        staticClass: "customFieldClass",
        class: [_vm.beforeText ? "before-field" : ""],
        attrs: {
          dense: _vm.dense,
          "stack-label": "",
          color: "orange-7",
          label: _vm.label,
          disable: _vm.disabled || !_vm.editable,
          rules: !_vm.required ? null : [(val) => !!val || ""],
        },
        scopedSlots: _vm._u(
          [
            _vm.label
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            [
                              !_vm.required
                                ? _c("i", {
                                    staticClass:
                                      "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                                  })
                                : _vm._e(),
                              _c("font", { staticClass: "formLabelTitle" }, [
                                _vm._v(_vm._s(_vm.label)),
                              ]),
                              _vm.required
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons labelfrontIcon text-requiredColor",
                                    },
                                    [_vm._v("check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._t("label-after"),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "control",
              fn: function () {
                return [
                  !_vm.editing
                    ? [
                        _vm._v(
                          " " + _vm._s(_vm.valueText || _vm.placeholder) + " "
                        ),
                      ]
                    : [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.valueText,
                              expression: "valueText",
                            },
                          ],
                          ref: "custom-editing-input",
                          staticClass: "col-12 custom-editing-input",
                          attrs: { type: "text" },
                          domProps: { value: _vm.valueText },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.valueText = $event.target.value
                              },
                              _vm.datachange,
                            ],
                          },
                        }),
                      ],
                ]
              },
              proxy: true,
            },
            {
              key: "before",
              fn: function () {
                return [
                  _vm.beforeText
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white",
                          },
                          [_vm._v(" " + _vm._s(_vm.beforeText) + " ")]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "append",
              fn: function () {
                return [
                  !_vm.disabled && _vm.editable && _vm.isWrite
                    ? _c("q-icon", {
                        staticClass: "cursor-pointer",
                        class: [_vm.editing ? "text-red" : ""],
                        attrs: { name: "edit" },
                        on: { click: _vm.edit },
                      })
                    : _vm._e(),
                  !_vm.disabled && _vm.editable
                    ? _c("q-icon", {
                        staticClass: "cursor-pointer",
                        attrs: { name: "search" },
                        on: { click: _vm.search },
                      })
                    : _vm._e(),
                  !_vm.disabled && _vm.editable
                    ? _c("q-icon", {
                        staticClass: "cursor-pointer",
                        attrs: { name: "close" },
                        on: { click: _vm.reset },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.valueText,
          callback: function ($$v) {
            _vm.valueText = $$v
          },
          expression: "valueText",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }