var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CurvedBottomNavigation", {
        attrs: { options: _vm.options },
        on: {
          callbackSearchAction: _vm.searchAreaToggle,
          callbackMenuAction: _vm.menuToggle,
          callbackSwpAction: _vm.swpToggle,
          callbackAction: _vm.callbackAction,
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }